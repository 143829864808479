import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import fetch from "isomorphic-fetch"

export const link = createHttpLink({
  fetch,
  uri: `https://gapi.storyblok.com/v1/api`,
  headers: {
    token: `3Z5LEGCjSWFR8mjwNYDSWAtt`,
    version: `draft`,
  },
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
})
