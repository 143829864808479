// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// Import Tailwind Globals
import "./src/styles/vendor/slick/slick.css"
import "./src/styles/global.css"

import React from "react"
import { SiteProvider } from "./src/context/SiteContext"
import { ApolloProvider } from "@apollo/client"
import { client } from "./src/apollo/client"
import { AnimatePresence } from "framer-motion"

export const wrapRootElement = ({ element }) => {
  return (
    <AnimatePresence>
      <SiteProvider>
        <ApolloProvider client={client}>{element}</ApolloProvider>
      </SiteProvider>
    </AnimatePresence>
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = []
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    window.scrollTo(0, 0)
  }
  return false
}
